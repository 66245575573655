<template>
    <b-row>
        <b-col md="3" sm="4" class="my-1">
            <b-form-group class="mb-0">
                <label class="d-inline-block text-sm-left mr-50"> </label>
                <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-50" />
            </b-form-group>
        </b-col>
        <b-col md="3" sm="8" class="my-1">
        </b-col>
        <b-col md="6" class="my-1">

            <b-form-group label="" label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="filterInput"
                class="mb-0">
                <b-input-group size="sm">
                    <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
                    <b-input-group-append>
                        <b-button :disabled="!filter" @click="filter = ''">
                            Clear
                        </b-button>
                    </b-input-group-append>
                </b-input-group>
            </b-form-group>
        </b-col>

        <b-col cols="12">
            <b-table striped hover responsive :per-page="perPage" :current-page="currentPage" :items="items"
                :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection"
                :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered">
                <template #cell(aduan)="data"> 
                    <b-row class="mlm-5 "> 
                        <b-col md="3">
                            <small>Kategori  Rawan </small>
                            <h3 class="text-warning"><strong>{{ data.item.kategori_titik_rawan }} </strong></h3>
                           
                            <b-button block variant="primary" size="sm" v-b-modal.modal-2 @click="DetailData(data.item)">
                                <feather-icon icon="ListIcon" size="15" /> Detail</b-button>
                        </b-col>
                        <b-col md="7"> 
                            <h6><small>Title </small><br> <b>{{ data.item.deskripsi }}</b></h6> 
                            <h6><small>Lokasi<br><strong> {{ data.item.nm_wil }}</strong><br>
                                    <feather-icon icon="MapPinIcon" size="15" /> Koordinat  {{ data.item.koordinat }} </small>
                            </h6> 
                        </b-col>
                        <b-col md="2"> 
                            <b-button block variant="warning" size="sm" v-b-modal.modal-3 @click="EditData(data.item)">
                                <feather-icon icon="EditIcon" size="15" /> Edit</b-button>
                            <b-button block variant="danger" size="sm" @click="DeleteData(data.item)">
                                <feather-icon icon="Trash2Icon" size="15" /> Delete</b-button>
                        </b-col>
                    </b-row>
                </template>
            </b-table>
        </b-col>

        <b-col cols="6">
            Total Row {{ items.length }}
        </b-col>
        <b-col cols="6">
            <b-pagination v-model="currentPage" :total-rows="items.length" :per-page="perPage" align="right" size="sm"
                class="my-0" />
        </b-col>
    </b-row>
</template>

<script>
    import {
        BTable,
        BAvatar,
        BBadge,
        BRow,
        BCol,
        BFormGroup,
        BFormSelect,
        BPagination,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BButton,
    } from 'bootstrap-vue'

    export default {
        components: {
            BTable,
            BAvatar,
            BBadge,
            BRow,
            BCol,
            BFormGroup,
            BFormSelect,
            BPagination,
            BInputGroup,
            BFormInput,
            BInputGroupAppend,
            BButton,
        },
        props: {
            rsitems: {}
        },
        data() {
            return {
                perPage: 3,
                pageOptions: [3, 5, 10, 50, 100,1000],
                totalRows: 1,
                currentPage: 1,
                sortBy: '',
                sortDesc: false,
                sortDirection: 'asc',
                filter: null,
                filterOn: [],
                fields: [{
                    key: 'aduan',
                    label: '',
                    thStyle: {
                        display: 'none'
                    }
                }],


            }
        },
        emits : ["DeleteData", "DetailData", "EditData"],
        computed: {
            items: {
                get: function () {
                    return this.rsitems;
                },
                set: function (newValue) {
                    return this.rsitems = newValue;
                },
            },
            sortOptions() {
                // Create an options list from our fields
                return this.fields
                    .filter(f => f.sortable)
                    .map(f => ({
                        text: f.label,
                        value: f.key
                    }))
            },
        },
        mounted() {
            // Set the initial number of items
            this.totalRows = this.items.length
        },
        methods: {
            EditData(value){
                this.$emit("EditData", value)
            },
            DetailData(value){
                this.$emit("DetailData", value)
            },
            DeleteData(value){
                if(confirm("Apakah anda yakin akan menghapus data ini..?") == true){
                    this.$emit("DeleteData", value);
                }
            },
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length
                this.currentPage = 1
            },
        },
    }
</script>