<template>
    <div>
        <b-row class="match-height">
            <b-col md="12">
                <b-card>
                    <b-row>
                        <b-col md="6">
                            <b-form-group label="Jenis Rawan">
                                <v-select v-model="id_kategori" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    @input="dataKategori()" label="kategori_titik_rawan" :options="kategori_rawan" />
                            </b-form-group>
                        </b-col>
                        <b-col md="3">
                        </b-col>
                        <b-col md="3">
                            <b-button block variant="primary" v-b-modal.modal-1 @click="TambahData()">
                                <feather-icon icon='PlusIcon'></feather-icon> Tambah Titik Rawan
                            </b-button>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
            <b-col md="12">
                <b-card>
                    <tabel_data :rsitems=rsitems @DetailData=DetailData @DeleteData=DeleteData @EditData=EditData>
                    </tabel_data>
                </b-card>
            </b-col>
        </b-row>
        <inputtitikrawan :kapupaten=kapupaten :kategori_rawan=kategori_rawan @simpan=simpan :title=title
            :rsDetail=rsDetail></inputtitikrawan>
            <edit_tanggapan :kapupaten=kapupaten :kategori_rawan=kategori_rawan @simpan=simpan :title=title
            :rsDetail=rsDetail></edit_tanggapan>
        <detail :rsDetail=rsDetail></detail>

    </div>
</template>

<script>
    import Base from '@/config/Mixins_base';
    import axios from '@/config/Axios';

    import vSelect from 'vue-select'
    import tabel_data from './component/tabel.vue';
    import detail from './component/detail.vue'
    import {
        BRow,
        BCol,
        BCard,
        BButton
    } from 'bootstrap-vue'
    import inputtitikrawan from './component/inputtitikRawan.vue';
    import edit_tanggapan from './component/editTitikRawan.vue';
    export default {
        components: {
            BRow,
            BCol,
            BCard,
            BButton,
            tabel_data,
            vSelect,
            inputtitikrawan,
            detail,edit_tanggapan

        },
        mixins: [Base],
        data() {
            return {
                title: '',
                id_kategori: '',
                rsitems: [],
                id_wilayah: "",
                kapupaten: [],
                kategori_rawan: [],
                rsDetail: {}
            }
        },
        mounted() {
            this.cek_token();
            this.load_data();
            this.titik_rawan_kategori();
            this.get_kabupaten();

        },
        methods: {
            
            TambahData() {
                this.rsDetail = {}
                this.title = "Tambah Titik Rawan"
            },
            EditData(value) {
                this.title = "Edit Titik Rawan"
                this.rsDetail = value
            },
            dataKategori() {
                alert();
                if (id_kategori.id_kategori == '0') {
                    this.id_kategori = ''
                    this.load_data();
                }
                this.id_kategori = id_kategori.id_kategori
                this.load_data();
            },
            DetailData(value) {
                this.rsDetail = value;
            },
            async load_data() {
                const self = this;
                await axios({
                        method: 'PUT',
                        url: '/api/titik_rawan/input/load_data',
                        data: {
                            id_kategori: self.id_kategori
                        },
                        headers: {
                            'Authorization': self.isAuthenticated
                        }
                    })
                    .then(function (response) {
                        self.rsitems = response.data.result;

                    }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                    });
            },
            async titik_rawan_kategori() {
                const self = this;
                await axios({
                        method: 'PUT',
                        url: '/api/titik_rawan/input/titik_rawan_kategori',
                        data: {

                        },
                        headers: {
                            'Authorization': self.isAuthenticated
                        }
                    })
                    .then(function (response) {
                        self.kategori_rawan = response.data.result;

                    }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                    });
            },
            async get_kabupaten() {
                const self = this;
                await axios({
                        method: 'PUT',
                        url: '/api/titik_rawan/input/kabupaten',
                        data: {

                        },
                        headers: {
                            'Authorization': self.isAuthenticated
                        }
                    })
                    .then(function (response) {
                        self.kapupaten = response.data.result;

                    }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                    });
            },
            async simpan() {
                this.load_data();
            },
            async update(value) {
                const self = this;
                await axios({
                        method: 'POST',
                        url: '/api/titik_rawan/input/update',
                        data: {
                            id_kategori: value.id_kategori,
                            id_wilayah: value.id_wilayah,
                            koordinat: value.koordinat,
                            deskripsi: value.deskripsi,
                            permasalahan: value.permasalahan,
                            rekomendasi_penanganan: value.rekomendasi_penanganan,
                            vidio: value.vidio,
                            foto_1: value.foto_1,
                            foto_2: value.foto_2,
                            foto_3: value.foto_3,
                            foto_4: value.foto_4,
                            id: value.id
                        },
                        headers: {
                            'Authorization': self.isAuthenticated
                        }
                    })
                    .then(function (response) {
                        self.notification('warning', "", response.data.message);
                        self.load_data();

                    }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                    });
            },
            async DeleteData(value) {
                const self = this;
                await axios({
                        method: 'POST',
                        url: '/api/titik_rawan/input/delete',
                        data: {
                            id: value.id
                        },
                        headers: {
                            'Authorization': self.isAuthenticated
                        }
                    })
                    .then(function (response) {
                        self.notification('warning', "", response.data.message);
                        self.load_data();

                    }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                    });
            },
        }

    }
</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>