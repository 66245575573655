<template>
    <div>
        <b-modal id="modal-1" :title="title" ok-only ok-title="Accept" size="lg" no-close-on-backdrop hide-footer>
            <validation-observer ref="simpleRules">
                <b-row>
                    <b-col md="12" xs="12">
                        <b-form-group label="Judul">
                            <validation-provider #default="{ errors }" name="Judul Titik Rawan" rules="required">
                                <b-form-input v-model="input.deskripsi" placeholder="Judul Titik Rawan"
                                    :state="errors.length > 0 ? false:null" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <b-col md="12">
                        <b-form-group label="Kategori Titik Rawan">
                            <validation-provider #default="{ errors }" name="kategori Titik Rawan" rules="required">
                                <v-select v-model="input.id_kategori"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="kategori_titik_rawan"
                                    :options="kategori_rawan" :state="errors.length > 0 ? false:null" />

                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-form-group label="Lokasi KabupatenKota">
                            <validation-provider #default="{ errors }" name="Lokasi Kabupaten" rules="required">
                                <v-select v-model="input.id_wilayah" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    label="nm_wil" :options="kapupaten" :state="errors.length > 0 ? false:null" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row class="mb-1">
                    <b-col md="12"><label for="textarea-default">Titik Koorinat</label></b-col>
                    <b-col md="4">
                        <b-button block variant="warning" @click="getLocation">
                            <feather-icon icon='MapPinIcon'></feather-icon> Get Koordinat
                        </b-button>
                    </b-col>
                    <b-col md="6">
                        <validation-provider #default="{ errors }" name="Koordinat Lokasi" rules="required">
                            <b-form-input v-model="coordinates" :state="errors.length > 0 ? false:null"
                                :placeholder="input.koordinat" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-col>
                    <b-col md="2">
                        <a :href="'https://www.google.com/maps/search/?api=1&amp;query='+ coordinates" target="_blank">
                            <b-button block variant="primary" @click="getLocation">
                                <feather-icon icon='MapIcon'></feather-icon> view
                            </b-button>
                        </a>

                    </b-col>
                </b-row>
                <b-row>
                    <b-col md="12">
                        <b-form-group label="Permasalahan">
                            <b-form-textarea id="textarea-default" v-model="input.permasalahan" placeholder="Textarea"
                                rows="3" />
                        </b-form-group>
                    </b-col>
                    <b-col md="12">
                        <b-form-group label="Rekomendasi Penanganan">
                            <b-form-textarea id="textarea-default" placeholder="Textarea" rows="3" />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col md="6" xs="12">
                        <b-form-group label="Foto Lokasi 1">
                            <b-form-file v-model="file3" placeholder="" ref="file" id="customFile" @input="uploadFile"
                                size="sm" drop-placeholder="Drop file here..." />
                        </b-form-group>

                    </b-col>

                    <b-col md="6" xs="12">
                        <b-form-group label="Foto Lokasi 2">
                            <b-form-file v-model="file2" placeholder="" ref="file2" id="customFile2"
                                @input="uploadFile2" size="sm" drop-placeholder="Drop file here..." />
                        </b-form-group>

                    </b-col>
                    <b-col md="6" xs="12">
                        <b-form-group label="Foto Lokasi 3">
                            <b-form-file v-model="file3" placeholder="" ref="file3" id="customFile3"
                                @input="uploadFile3" size="sm" drop-placeholder="Drop file here..." />
                        </b-form-group>

                    </b-col>
                    <b-col md="6" xs="12">
                        <b-form-group label="Foto Lokasi 4">
                            <b-form-file v-model="file4" placeholder="" ref="file4" id="customFile4"
                                @input="uploadFile4" size="sm" drop-placeholder="Drop file here..." />
                        </b-form-group>

                    </b-col>
                </b-row>

                <hr>
                <b-row>
                    <b-col md="6">
                        <b-button variant="primary" class="mr-2" @click.prevent="validationForm">
                            <feather-icon icon="AlignJustifyIcon" size="15" /> Simpan
                        </b-button>
                        <b-button variant="warning" @click="close()">
                            <feather-icon icon="XIcon" size="15" /> Batalkan
                        </b-button>
                    </b-col>
                </b-row>
            </validation-observer>
        </b-modal>
    </div>
</template>

<script>
    import axios from '@/config/Axios'
    import Base from '@/config/Mixins_base'
    import {
        ref
    } from '@vue/composition-api'

    import {
        ValidationProvider,
        ValidationObserver
    } from 'vee-validate'
    import vSelect from 'vue-select'
    import {
        BForm,
        BCol,
        BModal,
        BButton,
        VBModal,
        BAlert,
        BFormInput,
        BRow,
        BFormGroup,
        BFormTextarea,
        BFormFile
    } from 'bootstrap-vue'
    import Ripple from 'vue-ripple-directive'
    import {
        required
    } from '@validations'

    export default {
        components: {
            BButton,
            BForm,
            BCol,
            BModal,
            BAlert,
            BFormInput,
            BRow,
            BFormGroup,
            BFormTextarea,
            ValidationProvider,
            ValidationObserver,
            BFormFile,
            vSelect
        },
        directives: {
            'b-modal': VBModal,
            Ripple,
        },
        mixins: [Base],
        emits: ["insert_data"],
        props: {
            title: {},
            kapupaten: {},
            kategori_rawan: {},
            rsDetail: {}
        },
        data() {
            return {
                required,
                content: '',
                coordinates: {
                    latitude: '',
                    longitude: ''
                },
                file: {},
                file2: {},
                file3: {},
                file4: {},
                fileUpload: {},
                fileUpload2: {},
                fileUpload3: {},
                fileUpload4: {},
            }
        },
        computed: {
            input: {
                get: function () {
                    return this.rsDetail;
                },
                set: function (newValue) {
                    return this.rsDetail = newValue;
                },
            },
        },
        emits: ['simpan'],
        methods: {
            uploadFile() {
                const isAuthenticated = localStorage.getItem('token_auth');
                this.file = this.$refs.file.files[0];
                let formData = new FormData();
                formData.append('file', this.file);
                const self = this;
                axios.post('/upload/titik_rawan', formData, {
                        headers: {
                            'Authorization': isAuthenticated,
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(function (response) {
                        self.file2 = {};
                        self.fileUpload = response.data.result.nm_file

                    })
                    .catch(function (error) {
                        console.log(error);
                    });

            },
            uploadFile2() {
                const isAuthenticated = localStorage.getItem('token_auth');
                this.file2 = this.$refs.file2.files[0];
                let formData = new FormData();
                formData.append('file', this.file2);
                const self = this;
                axios.post('/upload/titik_rawan', formData, {
                        headers: {
                            'Authorization': isAuthenticated,
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(function (response) {
                        self.file2 = {};
                        self.fileUpload2 = response.data.result.nm_file

                    })
                    .catch(function (error) {
                        console.log(error);
                    });

            },
            uploadFile3() {
                const isAuthenticated = localStorage.getItem('token_auth');
                this.file3 = this.$refs.file3.files[0];
                let formData = new FormData();
                formData.append('file', this.file3);
                const self = this;
                axios.post('/upload/titik_rawan', formData, {
                        headers: {
                            'Authorization': isAuthenticated,
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(function (response) {
                        self.file3 = {};
                        self.fileUpload3 = response.data.result.nm_file

                    })
                    .catch(function (error) {
                        console.log(error);
                    });

            },
            uploadFile4() {
                const isAuthenticated = localStorage.getItem('token_auth');
                this.file4 = this.$refs.file4.files[0];
                let formData = new FormData();
                formData.append('file', this.file4);
                const self = this;
                axios.post('/upload/titik_rawan', formData, {
                        headers: {
                            'Authorization': isAuthenticated,
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(function (response) {
                        self.file4 = {};
                        self.fileUpload4 = response.data.result.nm_file

                    })
                    .catch(function (error) {
                        console.log(error);
                    });

            },
            insert_data(value) {
                this.$emit("insert_data", value)
            },
            getLocation() {
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(this.showPosition);
                } else {
                    alert("Geolocation tidak didukung di browser ini.");
                }
            },
            showPosition(position) {
                this.coordinates = position.coords.latitude + ',' + position.coords.longitude
            },

            async simpan() {
                const isAuthenticated = localStorage.getItem('token_auth');
                const self = this;
                await axios({
                        method: 'POST',
                        url: '/api/titik_rawan/input/insert_data',
                        data: {
                            id_kategori: self.input.id_kategori.id_kategori,
                            id_wilayah: self.input.id_wilayah.id_wilayah,
                            koordinat: self.coordinates,
                            deskripsi: self.input.deskripsi,
                            permasalahan: self.input.permasalahan,
                            rekomendasi_penanganan: self.input.rekomendasi_penanganan,
                            vidio: self.input.vidio,
                            foto_1: self.fileUpload,
                            foto_2: self.fileUpload2,
                            foto_3: self.fileUpload3,
                            foto_4: self.fileUpload4,
                        },
                        headers: {
                            'Authorization': isAuthenticated
                        }
                    })
                    .then(function (response) {
                        self.notification('warning', "", response.data.message);

                    }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                    });
            },

            validationForm() {
                this.$refs.simpleRules.validate().then(success => {
                    if (success) {
                        this.simpan();
                        this.$emit('simpan', this.input);
                        this.$root.$emit('bv::hide::modal', 'modal-1'),
                            this.input = {}
                    }
                })
            },
            close() {
                this.$root.$emit('bv::hide::modal', 'modal-1')
            }
        },
        setup() {
            const refInputEl = ref(null)
            const previewEl = ref(null)
            const {
                inputImageRenderer
            } = useInputImageRenderer(refInputEl, previewEl)
            return {
                refInputEl,
                previewEl,
                inputImageRenderer,
            }
        },
    }
</script>
<style scoped>
    .ql-editor {
        box-sizing: border-box;
        line-height: 1.42;
        height: 100%;
        outline: none;
        overflow-y: auto;
        padding: 12px 15px;
        tab-size: 4;
        -moz-tab-size: 4;
        text-align: left;
        white-space: pre-wrap;
        word-wrap: break-word;
    }
</style>