<template>
    <div>
        <b-modal id="modal-2" title="Detail Titik Rawan" ok-only ok-title="Accept" size="lg" no-close-on-backdrop
            hide-footer> 
            <b-row>
                <b-col md="6">
                    <h6><small>Jenis Rawan</small> </h6>
                    <h6 class="text-warning" style="font-size: 50px;">{{rsDetail.kategori_titik_rawan}}</h6>
                </b-col>
                <b-col md="6">
                    <h6><small>Lokasi</small> </h6>
                    <h6>
                        <feather-icon icon="MapIcon" size="15"></feather-icon> {{rsDetail.nm_wil}}  
                    </h6>
                    <h6><small>Koordinat</small> </h6>
                    <h6>
                        <feather-icon icon="MapPinIcon" size="15"></feather-icon> {{rsDetail.koordinat}} 
                    </h6>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="12">
                    <b-alert variant="warning" show>
                        <div class="alert-body">
                            <h6>Judul</h6>
                            <p class="text-primary"><small>{{rsDetail.deskripsi}}</small></p>
                        </div>
                    </b-alert>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="12">
                    <b-alert variant="danger" show>
                        <div class="alert-body">
                            <h6>Deskripsi</h6>
                            <p class="text-primary"><small v-html="rsDetail.permasalahan">
                                </small></p>
                        </div>
                    </b-alert>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="12">
                    <b-alert variant="primary" show>
                        <div class="alert-body">
                            <h6>Rekomendasi</h6>
                            <p class="text-primary"><small v-html="rsDetail.rekomendasi_penanganan"></small></p>
                        </div>
                    </b-alert>
                </b-col>
            </b-row>

            <b-row v-show="rsDetail.foto_1 != ''">
                <b-col md="12" class="text-primary">
                    <feather-icon icon="ImageIcon" size="15" /> Lampiran Foto
                </b-col>
                <b-col md="6" class="mt-1" v-show="rsDetail.foto_1 != ''"> 
                    <b-img thumbnail fluid :src="rsDetail.foto_1" />
                </b-col>
                <b-col md="6" class="mt-1" v-show="rsDetail.foto_2 != ''"> 
                    <b-img thumbnail fluid :src="rsDetail.foto_2" />
                </b-col>
                <b-col md="6" class="mt-1" v-show="rsDetail.foto_3 != ''"> 
                    <b-img thumbnail fluid :src="rsDetail.foto_3" />
                </b-col> 
                <b-col md="6" class="mt-1" v-show="rsDetail.foto_4 != ''"> 
                    <b-img thumbnail fluid :src="rsDetail.foto_4" />
                </b-col>

            </b-row>
        </b-modal>
    </div>
</template>

<script>
    import {
        ValidationProvider,
        ValidationObserver
    } from 'vee-validate'
    import vSelect from 'vue-select'
    import {
        BForm,
        BCol,
        BModal,
        BButton,
        VBModal,
        BAlert,
        BFormInput,
        BRow,
        BFormGroup,
        BFormTextarea,
        BFormFile,
        BImg
    } from 'bootstrap-vue'
    import Ripple from 'vue-ripple-directive'
    import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
    export default {
        components: {
            BButton,
            BForm,
            BCol,
            BModal,
            BAlert,
            BFormInput,
            BRow,
            BFormGroup,
            BFormTextarea,
            ValidationProvider,
            ValidationObserver,
            BFormFile,
            vSelect,
            FeatherIcon,
            BImg
        },
        directives: {
            'b-modal': VBModal,
            Ripple,
        },
        props : {
            rsDetail : {}
        },
        data() {
            return {
                sideImg: require('@/assets/images/pages/coming-soon.svg'),
            }
        },
        emits: ['simpan'],
        methods: {

            close() {
                this.$root.$emit('bv::hide::modal', 'modal-2')
            }
        }
    }
</script>